import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { AlertController, Platform } from '@ionic/angular';
import { ScreensizeService } from './services/screensize.service';
import { MenuController } from '@ionic/angular';
import { Quali5carelibService } from 'quali5care-lib';
import { Location } from '@angular/common';
import exitIntent from 'exit-intent';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  navigate: any;

  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private screensizeService: ScreensizeService,
    private _router: Router,
    private menu: MenuController,
    public quali5lib: Quali5carelibService,
    private _location: Location
  ) {
    this.initializeApp();
    this.sideMenu();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.screensizeService.onResize(this.platform.width());
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  async confirmDelete(headerstr, messagestr, buttonstr) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert',
      header: headerstr,
      message: messagestr,
      mode: 'ios',
      buttons: [
        {
          cssClass: 'alertButtonN',
          text: 'Cancel',
          role: 'Cancel',
        },
        {
          cssClass: 'alertButtonY',
          text: buttonstr,
          role: 'Okay',
        },
      ],
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role == 'Okay') {
      return true;
    } else {
      return false;
    }
  }

  async signout() {
    const headerstr = 'Logout';
    const messagestr = 'Do you want to signout from your account?';

    const isconfirmed = await this.confirmDelete(
      headerstr,
      messagestr,
      'Logout'
    );

    if (isconfirmed) {
      localStorage.removeItem('customermobile');
      this.quali5lib.dataService.loggedincustomer = null;
      this._router.navigate(['/']);
    }
  }

  sideMenu() {
    this.navigate = [
      {
        title: 'My orders',
        url: '/orderhistory',
        icon: 'bag',
      },
      {
        title: 'Contact us',
        url: '/tabs/contactus',
        icon: 'call',
      } /*,
      {
        title: 'Refer & Earn',
        url: '/tabs/home',
        icon: 'wallet',
      }*/,
      {
        title: 'Nursing care',
        url: '/tabs/nursingcare',
        icon: 'people',
      },
      {
        title: 'Walker',
        url: '/categories/walker',
        icon: 'people',
      },
      {
        title: 'Hospital Bed',
        url: '/categories/hospitalbed',
        icon: 'people',
      },
      {
        title: 'About us',
        url: '/tabs/aboutus',
        icon: 'information-circle',
      } /*,
      {
        title: 'Signin/Signout',
        url: '/tabs/home',
        icon: 'log-in',
      },
      {
        title: 'Logout',
        url: '/tabs/home',
        icon: 'log-out',
      }*/,
      {
        title: 'Policies',
        icon: 'documents',
        children: [
          {
            title: 'Service Policy',
            url: '/tabs/servicepolicy',
            icon: 'document-attach',
          },
          {
            title: 'Privacy Policy',
            url: '/tabs/privacypolicy',
            icon: 'document-lock',
          },
          {
            title: 'Shipping & Cancellation Policy',
            url: '/tabs/shippingpolicy',
            icon: 'document-text',
          },
        ],
      },
      {
        title: 'Terms & Conditions',
        url: '/tabs/termscondition',
        icon: 'grid',
      },
      {
        title: 'Refer and Earn',
        url: '/tabs/refernearn',
        icon: 'people',
      },
      {
        title: 'Quali5Care Profile',
        url: '/tabs/qcprofile',
        icon: 'home',
      },
    ];
  }

  /*
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.checkHiddenDocument().then(() => {});
  }
  showalert = false;
  async checkHiddenDocument() {
    if (document.visibilityState === 'hidden') {
      if (!this.mobno) this.presentAlert();
    } else {
      // add logic
    }
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: 'App termination',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
  mobno;
  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'What Are you looking for?',
      subHeader: ' for Assistance share your contact detail',

      inputs: [
        {
          placeholder: 'Mobile Number',
          name: 'Mob',
          attributes: {
            maxlength: 10,
          },
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Application exit prevented!');
          },
        },
        {
          text: 'Save',
          handler: (data) => {
            this.mobno = data.Mob;
            console.log(data.Mob + 'Mob No saved!');
          },
        },
      ],
    });

    await alert.present();
  }
  */
}
