import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Title } from '@angular/platform-browser';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'categories',
    loadChildren: () =>
    import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'categories/walker',
    loadChildren: () =>
      import('./categories/walker/walker.module').then((m) => m.WalkerPageModule),
  },
  {
    path: 'categories/walker/walker-without-wheel-rental',
    loadChildren: () =>
      import('./categories/walker/without-wheel-rental/without-wheel-rental.module').then((m) => m.WithoutWheelRentalPageModule),
  },
  {
    path: 'categories/walker/walker-without-wheel-sale',
    loadChildren: () =>
      import('./categories/walker/without-wheel-sale/without-wheel-sale.module').then((m) => m.WithoutWheelSalePageModule),
  },
  {
    path: 'categories/walker/walker-with-wheel-rental',
    loadChildren: () =>
      import('./categories/walker/with-wheel-rental/with-wheel-rental.module').then((m) => m.WithWheelRentalPageModule),
  },
  {
    path: 'categories/walker/walker-with-wheel-sale',
    loadChildren: () =>
      import('./categories/walker/with-wheel-sale/with-wheel-sale.module').then((m) => m.WithWheelSalePageModule),
  },
  {
    path: 'categories/walker/walker-with-big-wheel-sale',
    loadChildren: () =>
      import('./categories/walker/with-big-wheel-sale/with-big-wheel-sale.module').then((m) => m.WithBigWheelSalePageModule),
  },
  {
    path: 'categories/walker/walker-with-big-wheel-rental',
    loadChildren: () =>
      import('./categories/walker/with-big-wheel-rental/with-big-wheel-rental.module').then((m) => m.WithBigWheelRentalPageModule),
  },
  {
    path: 'categories/hospitalbed',
    loadChildren: () =>
      import('./categories/hospitalbed/hospitalbed.module').then((m) => m.HospitalbedPageModule),
  },
  {
    path: 'categories/wheelchair',
    loadChildren: () =>
      import('./categories/wheelchair/wheelchair.module').then((m) => m.WheelchairPageModule),
  },
  {
    path: 'productdetail',
    loadChildren: () =>
      import('./productdetail/productdetail.module').then(
        (m) => m.ProductdetailPageModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./order/order.module').then((m) => m.OrderPageModule),
  },
  {
    path: 'orderhistory',
    loadChildren: () =>
      import('./orderhistory/orderhistory.module').then(
        (m) => m.OrderhistoryPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '/tabs/home',
  },
  {
    path: 'nursingcare',
    loadChildren: () => import('./nursingcare/nursingcare.module').then( m => m.NursingcarePageModule)
  },
  {
    path: 'categories/walker',
    loadChildren: () => import('./categories/walker/walker.module').then( m => m.WalkerPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then( m => m.CategoriesPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
